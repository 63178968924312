'use client'

import { Expanded } from '@rallycry/api-suite-typescript/dist/models/Expanded'
import { every, first, isArray, isString, isUndefined, some } from 'lodash-es'
import { useMemo } from 'react'
import useSWR, { SWRResponse, unstable_serialize, useSWRConfig } from 'swr'
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite'
import { Middleware, SWRHook } from 'swr'
import { useRef, useEffect, useCallback } from 'react'
import { getCacheKey } from './getCacheKey'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import { Awaited, PropType, Unarray } from '@/core/generic'
import { tryExtractJsonError } from '@/core/utils'

// options used by useSWRImmutable
// https://github.com/vercel/swr/blob/main/immutable/index.ts
const SWR_IMMUTABLE = {
  revalidateFirstPage: true,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
}

export interface EntityOptions<TRequest = any> {
  idOrKey?: string | number
  request?: Partial<TRequest>
  pageSize?: number
  expand?: string
  suspense?: boolean
  mutable?: boolean
  dedupingInterval?: number
  persist?: boolean
  paused?: boolean
  skipImpersonation?: boolean
  fallbackData?: any
}

interface ReadOptions<TRead, TCreate, TUpdate> extends EntityOptions {
  key: string
  metas?: SWRResponse<Record<string, any>, any>[]
  read?: TRead
  create?: TCreate
  update?: TUpdate
  remove?: (id: any) => Promise<void>
}

export const useReadEntity = <
  TRead extends (
    req: {
      id: number
      idOrKey: string
      expand: string
    },
    meta: Record<string, any>
  ) => Promise<any>,
  TCreate extends (req: any) => Promise<any>,
  TUpdate extends (id: any, req: any, expand: string) => Promise<any>
>(
  options: ReadOptions<TRead, TCreate, TUpdate>
) => {
  type ReadResult = Awaited<ReturnType<TRead>>

  const { user } = useFirebase()
  const { impersonation } = useImpersonation()
  const { mutate, suspense: globalSuspense } = useSWRConfig()

  const metas = options.metas
  const expand = options.expand || ''
  const hasMetas = some(metas)
  const hasMetasData = every(metas, it => !!it.data)
  const meta = metas?.reduce(
    (acc, val) => ({ ...acc, ...val.data }),
    {} as Record<string, number>
  )

  const load = options.idOrKey && (!hasMetas || hasMetasData) && !options.paused
  const cache = (idOrKey?: any) =>
    getCacheKey({
      req: (idOrKey || options.idOrKey)?.toString()!,
      expand,
      meta,
      uid: user?.uid,
      impersonation: options.skipImpersonation ? undefined : impersonation,
      optionKey: options.key
    })

  const metaLock = options.mutable ? {} : SWR_IMMUTABLE

  const suspense = isUndefined(options.suspense)
    ? globalSuspense
    : options.suspense

  const use = suspense ? [laggy(true)] : undefined

  const read = useSWR<ReadResult, any>(
    load ? cache(options.idOrKey) : null,
    async ([idOrKey, expand]: any) => {
      try {
        const res = await options.read?.(
          { id: parseInt(idOrKey), idOrKey, expand },
          meta || {}
        )!
        return res
      } catch (e: any) {
        const err = await tryExtractJsonError(e)

        throw new Error(
          `Unable to read ${options.key}-${options.idOrKey}\n${JSON.stringify(
            err
          )}\n`
        )
      }
    },
    {
      ...metaLock,
      shouldRetryOnError: false,
      suspense,
      fallbackData: options.fallbackData,
      dedupingInterval: options.dedupingInterval
        ? options.dedupingInterval
        : hasMetas || options.persist
        ? 1000 * 60 * 60
        : 15000,
      use,
      keepPreviousData: true
    }
  )

  const create = async (cmd: Parameters<TCreate>[0]) => {
    const res = await options.create?.({ ...cmd, expand })
    await Promise.allSettled(options?.metas?.map(meta => meta.mutate()) || [])
    return res as ReadResult
  }

  //update function does not auto-map expander to request. must be added by caller (provided in TUpdate).
  const update = async (id: number, cmd: Parameters<TUpdate>[1]) => {
    const res = await options.update?.(id, cmd, expand)
    await mutate(cache(id), res, false)
    await Promise.allSettled(options?.metas?.map(meta => meta.mutate()) || [])
    await read.mutate()
    return res as ReadResult
  }

  const remove = async (id: number) => {
    await options.remove?.(id)
    await mutate(cache(id), null, false)
    await Promise.allSettled(options?.metas?.map(meta => meta.mutate()) || [])
  }

  return {
    read,
    create,
    update,
    remove,
    cache
  }
}

export const useQueryEntity = <
  TQuery extends (req: any, meta: Record<string, any>) => Promise<any>,
  TCreate extends (req: any) => Promise<any>,
  TUpdate extends (id: any, req: any, expand: string) => Promise<any>
>(
  options: ReadOptions<TQuery, TCreate, TUpdate>
) => {
  type QueryResult = Awaited<ReturnType<TQuery>>
  const { user } = useFirebase()
  const { impersonation } = useImpersonation()
  const { suspense: globalSuspense } = useSWRConfig()

  const metas = options.metas
  const expand = options.expand || ''
  const hasMetas = some(metas)
  const hasMetasData = every(metas, it => !!it.data)
  const meta = metas?.reduce(
    (acc, val) => ({ ...acc, ...val.data }),
    {} as Record<string, number>
  )

  // skip loading query results until request is ready
  const load =
    !!options.request && (!hasMetas || hasMetasData) && !options.paused

  const cache = (index: number) =>
    getCacheKey({
      req: {
        ...options.request,
        page: index,
        size: options.pageSize || 15,
        expand
      } as unknown as TQuery,
      expand,
      meta,
      uid: user?.uid,
      impersonation: options.skipImpersonation ? undefined : impersonation,
      optionKey: options.key
    })

  const metaLock = options.mutable ? {} : SWR_IMMUTABLE

  const suspense = isUndefined(options.suspense)
    ? globalSuspense
    : options.suspense
  const use = suspense ? [laggy(true)] : undefined

  const query: SWRInfiniteResponse<
    Awaited<ReturnType<TQuery>>,
    any
  > = useSWRInfinite<QueryResult, any>(
    idx => (load ? cache(idx) : null),
    async req => {
      try {
        const res = await options.read?.(req[0], meta || {})!
        return res
      } catch (e: any) {
        const err = await tryExtractJsonError(e)

        throw new Error(
          `Unable to read ${options.key}-${options.idOrKey}\n${JSON.stringify(
            err
          )}\n`
        )
      }
    },
    {
      ...metaLock,
      shouldRetryOnError: false,
      suspense,
      fallbackData: options.fallbackData,
      dedupingInterval: options.dedupingInterval
        ? options.dedupingInterval
        : hasMetas || options.persist
        ? 1000 * 60 * 60
        : 15000,
      use,
      keepPreviousData: true
    }
  )

  const create = async (cmd: Parameters<TCreate>[0]) => {
    const res = await options.create?.({ ...cmd, expand })
    if (hasMetas) {
      await Promise.allSettled(options?.metas?.map(meta => meta.mutate()) || [])
    } else {
      await query.mutate()
    }
    return res as any
  }

  //update function does not auto-map expander to request. must be added by caller (provided in TUpdate).
  const update = async (id: any, cmd: Parameters<TUpdate>[1]) => {
    const res = await options.update?.(id, cmd, expand)
    if (hasMetas) {
      await Promise.allSettled(options?.metas?.map(meta => meta.mutate()) || [])
    } else {
      await query.mutate()
    }
    return res as any
  }

  const remove = async (id: any) => {
    await options.remove?.(id)
    if (hasMetas) {
      await Promise.allSettled(options?.metas?.map(meta => meta.mutate()) || [])
    } else {
      await query.mutate()
    }
    await query.mutate()
  }

  const flat = useMemo(() => flattenEntityQuery(query.data), [query.data])

  const totalElements: number = useMemo(
    () => first(query.data)?.totalElements || 0,
    [query.data]
  )

  const hasMore = useMemo(
    () => flat?.length < totalElements,
    [flat, totalElements]
  )

  return {
    query,
    flat,
    totalElements,
    hasMore,
    create,
    update,
    remove,
    cache
  }
}

// By convention *most* query page results look like { content: [], totalElements: number, _expanded: Expand }[].
// This helper flattens them into a single array of content and maps the corresponding page's
// _expanded onto each content item for ease of access.
export const flattenEntityQuery = <
  T extends Record<string, any> & { _expanded?: Expanded; _links?: any }
>(
  data?: T[]
) => {
  type QueryContent = NonNullable<Unarray<PropType<Unarray<T>, 'content'>>>
  return (
    data?.reduce(
      (acc, value) => {
        const merged: QueryContent[] = value.content || []
        merged.forEach(it => {
          if (isString(it)) return
          it._expanded = value._expanded || it._expanded
          it._links = value._links || it._links
        })
        acc.push(...merged)
        return acc
      },
      [] as (QueryContent & { _expanded?: Expanded })[]
    ) || []
  )
}

declare module 'swr' {
  interface SWRResponse {
    isLagging?: boolean
    resetLaggy?: () => void
  }
}

// https://swr.vercel.app/docs/middleware#keep-previous-result
// This is a SWR middleware for keeping the data even if key changes.
export const laggy =
  (revalidateOnMount: boolean): Middleware =>
  (useSWRNext: SWRHook) =>
  (key, fetcher, config) => {
    const laggyDataRef = useRef<any>()

    // dont suspend if we can lag instead
    const cfg = !!laggyDataRef.current
      ? { ...config, suspense: false, revalidateOnMount }
      : config
    const swr = useSWRNext(key, fetcher, cfg)

    useEffect(() => {
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data
      }
    }, [swr.data])

    const resetLaggy = () => {
      laggyDataRef.current = undefined
    }

    const dataOrLaggyData =
      swr.data === undefined ? laggyDataRef.current : swr.data

    const isLagging =
      swr.data === undefined && laggyDataRef.current !== undefined

    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy
    })
  }
