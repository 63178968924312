'use client'

import { ChatProvider } from '@rallycry/api-suite-typescript/dist/models/ChatProvider'
import { GameKind } from '@rallycry/api-suite-typescript/dist/models/GameKind'
import { mapValues } from 'lodash-es'
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useSessionStorage } from '@mantine/hooks'
import { useOrganizationAttributes } from '@/entity/organization/useOrganizationAttributes'
import { TranslationNamespace } from '@/core/translation'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { useQuery } from '@/core/hooks/useQuery'

export const DEFAULT_STRAPI_RC_RESOURCE = 'strapi-rc-resource'

/**
 * Merges features from org CMS and RC API
 */

export const useFeaturesApi = () => {
  const q = useQuery()
  const devLoggedOut = !!q.get('loggedOut')
  const devGoogle = !!q.get('google')
  const devEmail = !!q.get('email')
  const devXstate = !!q.get('xstate')
  const noLayoutQuery = !!q.get('noLayout')

  const [elasticFallback, setElasticFallback] = useState(false)

  const { attributes, profile, create, remove, update } =
    useOrganizationAttributes()

  const [ageGate, setAgeGate] = useLocalStorage({
    key: 'age_gate',
    defaultValue: {
      passed: false,
      lastAttempt: new Date(2020, 0)
    }
  })

  const [filterProfanity, setFilterProfanity] = useLocalStorage({
    key: 'filter-profanity',
    defaultValue: true
  })

  const [noLayout, setNoLayout] = useSessionStorage({
    key: 'noLayout',
    defaultValue: noLayoutQuery
  })

  const featAttrs = attributes
    ?.filter(it => Object.keys(DEFAULT_ORG_FEATURES).includes(it.path!))
    .reduce(
      (acc, val) => {
        acc[val.path!] = val.value
        return acc
      },
      {} as Record<string, any>
    )

  if (!profile?.primaryDiscordInviteUrl) {
    featAttrs.cfgChatProvider = featAttrs.featChat
      ? ChatProvider.RALLYCRY
      : ChatProvider.NONE
  }

  const [userDarkMode, setUserDarkMode] = useLocalStorage({
    key: 'user-dark-mode',
    defaultValue:
      !!featAttrs['featDefaultDarkMode'] ||
      (window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
  })

  useEffect(() => {
    if (noLayoutQuery) setNoLayout(noLayoutQuery)
  }, [noLayoutQuery, setNoLayout])

  const toggleFeat = async (path: string) => {
    const template =
      DEFAULT_ORG_FEATURES[path as keyof typeof DEFAULT_ORG_FEATURES]
    const existing = attributes?.find(it => it.path === path)

    // attempt to only keep feat teams if they're the opposite of the default value
    if (existing && existing.id && existing.value !== template.value) {
      await remove(existing.id)
    } else {
      await create({ path, value: !template.value as any })
    }
  }

  const updateCfg = async (path: string, value: any) => {
    const existing = attributes?.find(it => it.path === path)
    if (existing && existing.id) {
      if (value) {
        await update(existing.id, { path, value })
      } else {
        await remove(existing.id)
      }
    } else {
      await create({ path, value })
    }
  }

  return {
    attributes,
    ...mapValues(DEFAULT_ORG_FEATURES, it => it.value),
    ...featAttrs,
    ageGate,
    setAgeGate,
    userDarkMode,
    setUserDarkMode,
    filterProfanity,
    setFilterProfanity,
    setElasticFallback,
    elasticApi: elasticFallback
      ? '/content/'
      : 'https://dfc4b5f3396e40769d3c136111c29a90.ent-search.us-central1.gcp.cloud.es.io/api/as/v1/engines/',
    toggleFeat,
    updateCfg,

    // dev environment
    devLoggedOut,
    noLayout,
    devGoogle,
    devEmail,
    devXstate
  }
}

export const useFeatures = () => useContext(FeatureContext)

// Exported for Storybook Mocking only
export type FeatureType = ReturnType<typeof useFeaturesApi>
export const FeatureContext = createContext({} as FeatureType)
export const FeatureProvider = (props: PropsWithChildren) => {
  const api = useFeaturesApi()
  return (
    <FeatureContext.Provider value={api}>
      {props.children}
    </FeatureContext.Provider>
  )
}

export enum FeatureInput {
  JSON = 'json',
  Text = 'text',
  Numeric = 'numeric',
  Toggle = 'toggle',
  Select = 'select',
  Image = 'image',
  None = 'none'
}

export enum FeatureCategory {
  Communications = 'communications',
  Community = 'community',
  Squads = 'squads',
  DevOps = 'devops',
  Images = 'images',
  Registration = 'registration',
  Styling = 'styling',
  Whitelabeling = 'whitelabeling'
}

export const DEFAULT_ORG_FEATURES = {
  featAgeGate: {
    category: FeatureCategory.Registration,
    value: false,
    description:
      'Turn on to verify user age before accessing content. Turn off to disable age verification.',
    input: FeatureInput.Toggle
  },
  featGatherRealName: {
    category: FeatureCategory.Registration,
    value: true,
    description:
      "Request user's real names during account creation. Enable this option to collect full names for user profiles",
    input: FeatureInput.Toggle
  },
  settingMinAge: {
    category: FeatureCategory.Registration,
    value: 13,
    description:
      'Set the minimum age users must be to access content. Enter the minimum age for birthdate verification.',
    input: FeatureInput.Numeric
  },
  featAuthProviders: {
    category: FeatureCategory.Registration,
    value: '',
    description: 'Comma separated list of Networks allowed for Login',
    input: FeatureInput.None
  },
  featAuthWithEmail: {
    category: FeatureCategory.Registration,
    value: true,
    description:
      'Enable or disable login using email authentication. Toggle to allow users to sign in with their email address.',
    input: FeatureInput.Toggle
  },
  cfgAvatarRedirect: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description:
      'Link user to a page other than root after clicking on the avatar',
    input: FeatureInput.Text
  },
  featTwitterFeeds: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description: 'Enable display of twitter timeline feeds',
    input: FeatureInput.Toggle
  },
  cfgTwitchChannels: {
    category: FeatureCategory.Whitelabeling,
    value: '',
    description:
      'Set Twitch channels to highlight when live (comma separated).',
    input: FeatureInput.Text
  },
  featDependentAccounts: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description: 'Allow user to utilize dependent accounts',
    input: FeatureInput.Toggle
  },

  featChat: {
    category: FeatureCategory.Communications,
    value: false,
    description: 'Enable Rally Cry chat tech.',
    input: FeatureInput.Toggle
  },
  cfgChatProvider: {
    category: FeatureCategory.Communications,
    value: ChatProvider.DISCORD,
    description:
      'Default chat provider used when creating new competitions on this org.',
    input: FeatureInput.Select,
    options: Object.values(ChatProvider)
  },
  featChatSupport: {
    category: FeatureCategory.Communications,
    value: false,
    description: 'Enable contact support flow (implicitly enables featChat).',
    input: FeatureInput.Toggle
  },
  featChatGifs: {
    category: FeatureCategory.Communications,
    value: true,
    description: 'Allow users to insert tenor gifs into chat',
    input: FeatureInput.Toggle
  },
  featChatProfanityFilter: {
    category: FeatureCategory.Communications,
    value: true,
    description: 'Enable to force profanity filter on chat',
    input: FeatureInput.Toggle
  },
  cfgChatAllowedDomains: {
    category: FeatureCategory.Communications,
    value: 'airforcegaming.com',
    description:
      'Comma separated list of additional domains to allow in chats. rallycry.gg included by default along with a few others for images.',
    input: FeatureInput.Text
  },
  featElasticResources: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description: 'Enables Resource Portal for org',
    input: FeatureInput.Toggle
  },
  featShopLink: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description:
      'Link to store added to global navigation once filled in. Leave empty or single space to hide.',
    input: FeatureInput.Text
  },
  featContactLink: {
    category: FeatureCategory.Whitelabeling,
    value: '',
    description:
      'Add a custom direct contact link for the organization (ie mailto: )',
    input: FeatureInput.Text
  },
  feat24hTime: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description: 'Show times in 24h format',
    input: FeatureInput.Toggle
  },
  featUserLocale: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description: 'Allow user to set their locale preference',
    input: FeatureInput.Toggle
  },
  featRallyCryFooter: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description: 'Show expanded info about RC on footer',
    input: FeatureInput.Toggle
  },
  featBnetAccounts: {
    category: FeatureCategory.Whitelabeling,
    value: true,
    description: 'Allow users to connect battle.net in account settings',
    input: FeatureInput.Toggle
  },
  featRiotAccounts: {
    category: FeatureCategory.Whitelabeling,
    value: true,
    description: 'Allow users to connect riot in account settings',
    input: FeatureInput.Toggle
  },
  featTwitchAccounts: {
    category: FeatureCategory.Whitelabeling,
    value: true,
    description: 'Allow users to connect twitch in account settings',
    input: FeatureInput.Toggle
  },
  featGameKindOrgFilter: {
    category: FeatureCategory.Whitelabeling,
    value: GameKind.VIDEOGAME,
    description:
      'Toggle what kind of games an organization should show. E.g. VIDEO_GAME or SPORT',
    input: FeatureInput.Text
  },
  featDirectAddToTeamDefault: {
    category: FeatureCategory.Community,
    value: false,
    description:
      'Enable "direct add to team" by default for newly created communities on this organization.',
    input: FeatureInput.Toggle
  },
  featTeamMatches: {
    category: FeatureCategory.DevOps,
    value: false,
    description: 'Enable display of team match history (WIP)',
    input: FeatureInput.Toggle
  },
  featHideCommunity: {
    category: FeatureCategory.Community,
    value: false,
    description:
      'Disable community-related features like chat. Turn on to hide these elements from users.',
    input: FeatureInput.Toggle
  },
  cfgClassificationType: {
    category: FeatureCategory.Community,
    value: '',
    description:
      'Determines classification type options in community settings.',
    input: FeatureInput.Select,
    options: [
      TranslationNamespace.DepartmentType,
      TranslationNamespace.AfgSpeciality,
      TranslationNamespace.Military,
      TranslationNamespace.SchoolFraternity,
      TranslationNamespace.SchoolGeneric,
      TranslationNamespace.YouthGeneric,
      TranslationNamespace.YouthGenericV2
    ]
  },
  featShowConcludedCompetitions: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description:
      'Included concluded competitions in default discovery filtering.',
    input: FeatureInput.Toggle
  },
  featAllowOtherClassification: {
    category: FeatureCategory.Community,
    value: false,
    description:
      'Enable users to type custom role names. Turn on to allow free-form typing for roles in the community.',
    input: FeatureInput.Toggle
  },
  featCommunityContactToCreate: {
    category: FeatureCategory.Community,
    value: true,
    description:
      'Enables "Contact Us" to create a community in the footer of the Community Discovery page. (defaults true).',
    input: FeatureInput.Toggle
  },
  featCodeOfConduct: {
    category: FeatureCategory.Community,
    value: '',
    description:
      "Provide a link to your Community's Code of Conduct. Adds a URL for users to access the guidelines and rules.",
    input: FeatureInput.Text
  },
  featShowCommunityActivity: {
    category: FeatureCategory.Community,
    value: true,
    description:
      'Show recent activity on community pages. Enable this to display posts, updates, and interactions from community members.',
    input: FeatureInput.Toggle
  },
  cfgSearchToken: {
    category: FeatureCategory.DevOps,
    value: 'search-uozqgg5tne6hkx27et1adjau',
    description: 'Public search token scoped for the current org',
    input: FeatureInput.Text
  },
  cfgPartnerBlog: {
    category: FeatureCategory.DevOps,
    value: 'strapi-rc-blog',
    description: 'Meta engine to use for blogs',
    input: FeatureInput.Text
  },
  cfgPartnerResource: {
    category: FeatureCategory.DevOps,
    value: DEFAULT_STRAPI_RC_RESOURCE,
    description: 'Meta engine to use for resources',
    input: FeatureInput.Text
  },
  cfgTenorApiKey: {
    category: FeatureCategory.DevOps,
    value: 'AIzaSyBwjyWBY4NAy0TLARrqHShU2kg-jCnJiJc',
    description: 'API key for tenor gif search',
    input: FeatureInput.Text
  },
  featSecureMode: {
    category: FeatureCategory.DevOps,
    value: process.env.IS_DEVELOPMENT === 'true' || false,
    description:
      'Blocks public views and style when enabled for unauthed users.',
    input: FeatureInput.Toggle
  },
  featMarketingOptOut: {
    category: FeatureCategory.DevOps,
    value: false,
    description:
      'Enable for users signing up on the organization to automatically opt out of marketing emails and not see the option on account creation',
    input: FeatureInput.Toggle
  },
  featLimitedUnauthed: {
    category: FeatureCategory.DevOps,
    value: false,
    description:
      'Prevents some routes on site from being viewed prior to login',
    input: FeatureInput.Toggle
  },
  featAdminInlineEdit: {
    category: FeatureCategory.DevOps,
    value: false,
    description: 'Enable easy loc string editing with CTRL+click',
    input: FeatureInput.Toggle
  },
  cfgPinnedHomeResources: {
    category: FeatureCategory.DevOps,
    value: '[]',
    description:
      'Resource IDs to promote on home page (should be done thru pin on resource)',
    input: FeatureInput.Text
  },

  // Style
  featBackgroundCenter: {
    category: FeatureCategory.Styling,
    value: false,
    description: 'Show background image',
    input: FeatureInput.Toggle
  },
  featStylePrimaryButton: {
    category: FeatureCategory.Styling,
    value: false,
    description: 'Primary Button inherits org colors',
    input: FeatureInput.Toggle
  },
  featStyleSecondaryButton: {
    category: FeatureCategory.Styling,
    value: false,
    description: 'Secondary Button inherits org colors',
    input: FeatureInput.Toggle
  },
  featHeroOpacity: {
    category: FeatureCategory.Styling,
    value: 50,
    description: 'Opacity % between 0 - 100 for any Herobox components',
    input: FeatureInput.Numeric
  },
  featHeroFade: {
    category: FeatureCategory.Styling,
    value: false,
    description: 'Gradient mask out landing hero to fade to content',
    input: FeatureInput.Toggle
  },
  cfgChallongeThemeLight: {
    category: FeatureCategory.Styling,
    value: '8137',
    description:
      'Key for light mode challonge theme, see rally.gg/challongehelp for more info',
    input: FeatureInput.Text
  },
  cfgChallongeThemeDark: {
    category: FeatureCategory.Styling,
    value: '8136',
    description:
      'Key for dark mode challonge theme, see rally.gg/challongehelp for more info',
    input: FeatureInput.Text
  },
  themeOverride: {
    category: FeatureCategory.Styling,
    value: {},
    description: 'Site-wide theme overrides for org.',
    input: FeatureInput.JSON
  },
  featCommunityDiscoveryAvatars: {
    category: FeatureCategory.Styling,
    value: true,
    description: 'Enable avatar images on community discovery view cards',
    input: FeatureInput.Toggle
  },
  featCommunityDiscoveryBanners: {
    category: FeatureCategory.Styling,
    value: false,
    description: 'Enable banner images on community discovery view cards',
    input: FeatureInput.Toggle
  },
  featCompetitionDiscoveryAvatars: {
    category: FeatureCategory.Styling,
    value: true,
    description: 'Enable avatar images on competition discovery view cards',
    input: FeatureInput.Toggle
  },
  featCompetitionDiscoveryBanners: {
    category: FeatureCategory.Styling,
    value: false,
    description: 'Enable banner images on competition discovery view cards',
    input: FeatureInput.Toggle
  },

  locale_v2_en: {
    category: FeatureCategory.DevOps,
    value: {},
    description:
      'Version 2 - Site-wide en string overrides. Supports all namespaces.',
    input: FeatureInput.JSON
  },
  locale_v2_pt_BR: {
    category: FeatureCategory.DevOps,
    value: {},
    description:
      'Version 2 - Site-wide pt-BR string overrides.  Supports all namespaces.',
    input: FeatureInput.JSON
  },
  featDefaultDarkMode: {
    category: FeatureCategory.Styling,
    value: false,
    description:
      'Defaults org to Dark Mode regardless of device preference (does not override user preference)',
    input: FeatureInput.Toggle
  },

  // WIP
  featDiscoveryImages: {
    category: FeatureCategory.Styling,
    value: false,
    description: 'Show the header art on discovery stylev4 views',
    input: FeatureInput.Toggle
  },
  featUserSchedule: {
    category: FeatureCategory.DevOps,
    value: false,
    description: 'Show user schedule on user settings',
    input: FeatureInput.Toggle
  },
  cfgCommunityXY: {
    category: FeatureCategory.DevOps,
    value: {
      enabled: false,
      image: 'https://cdn.rallycryapp.com/strapi/USA_6c92fc41d7.png',
      communities: []
    },
    description: 'Store XY coordinates for org communities.',
    input: FeatureInput.JSON
  },
  featCMSOnly: {
    category: FeatureCategory.DevOps,
    value: false,
    description: 'Turn off all the things except CMS. Disables login.',
    input: FeatureInput.Toggle
  },
  featSquads: {
    category: FeatureCategory.Squads,
    value: false,
    description: 'Enable squads on main side nav',
    input: FeatureInput.Toggle
  },
  featCommunitySquads: {
    category: FeatureCategory.Squads,
    value: false,
    description: 'Enable squads with community parents',
    input: FeatureInput.Toggle
  },
  featEntryCoaches: {
    category: FeatureCategory.Squads,
    value: false,
    description: 'Enable creating team as a coach instead of a player',
    input: FeatureInput.Toggle
  },
  featSquadCompetitionSearch: {
    category: FeatureCategory.Squads,
    value: false,
    description: 'Enable competition search on squad communities',
    input: FeatureInput.Toggle
  },
  cfgContactIframe: {
    category: FeatureCategory.Whitelabeling,
    value: '',
    description: 'Replace contact flow with a custom iframe url',
    input: FeatureInput.Text
  },
  imgOpenGraphPreview: {
    category: FeatureCategory.Images,
    value: '',
    description: 'Image for OpenGraph preview links. 1200x630px recommended.',
    input: FeatureInput.Image
  },
  imgPageBanner: {
    category: FeatureCategory.Images,
    value: 'https://cdn.rallycryapp.com/default-images/default_page_banner.png',
    description:
      'Page banner for discovery and other landing pages. 960x400px recommended',
    input: FeatureInput.Image
  },
  imgBackground: {
    category: FeatureCategory.Images,
    value: '',
    description: 'Background image for the website',
    input: FeatureInput.Image
  },
  imgCommunityAvatar: {
    category: FeatureCategory.Images,
    value:
      'https://cdn.rallycryapp.com/default-images/default_community_icon.png',
    description:
      'Fallback image for community avatar if none provided. 200x200px recommended',
    input: FeatureInput.Image
  },
  imgCommunityBanner: {
    category: FeatureCategory.Images,
    value:
      'https://cdn.rallycryapp.com/default-images/default_community_banner.png',
    description:
      'Fallback image for community banner if none provided. 960x200px recommended',
    input: FeatureInput.Image
  },
  imgTutorialDiscord: {
    category: FeatureCategory.Images,
    value: 'https://cdn.rallycryapp.com/default-images/join_discord.png',
    description: 'Default image for the Discord tutorial step',
    input: FeatureInput.Image
  },
  imgTutorialCommunity: {
    category: FeatureCategory.Images,
    value: 'https://cdn.rallycryapp.com/default-images/join_community.png',
    description: 'Default image for the Community tutorial step',
    input: FeatureInput.Image
  },
  imgTutorialGame: {
    category: FeatureCategory.Images,
    value: 'https://cdn.rallycryapp.com/default-images/game_preferences.png',
    description: 'Default image for the Game Preference tutorial step',
    input: FeatureInput.Image
  },
  featBroadcast: {
    category: FeatureCategory.Whitelabeling,
    value: false,
    description: 'Enable broadcast assets feature',
    input: FeatureInput.Toggle
  },
  featMatchmaking: {
    category: FeatureCategory.DevOps,
    value: false,
    description: 'Enable matchmaking / ladder system',
    input: FeatureInput.Toggle
  },
  featCompetitionAlertsV2: {
    category: FeatureCategory.DevOps,
    value: true,
    description: 'Enable new competition alerts',
    input: FeatureInput.Toggle
  },
  featOrgRedirect: {
    category: FeatureCategory.DevOps,
    value: '',
    description:
      'Enable org redirect to another org for deprecated organizations',
    input: FeatureInput.Text
  },
  featStringFinder: {
    category: FeatureCategory.DevOps,
    value: false,
    description: 'Enable string finder to locate unlocalized strings',
    input: FeatureInput.Toggle
  },
  featSeedAssign: {
    category: FeatureCategory.DevOps,
    value: true,
    description: 'Enable new seeding view',
    input: FeatureInput.Toggle
  }
}
